import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { SubscriptionContractSubscriptionStatus } from "@smartrr/shared/shopifyGraphQL/api";
import {
  AnalyticsActiveSubsOverTime,
  AnalyticsCancelledSubsOverTime,
  AnalyticsNewOrdersOverTime,
  AnalyticsNewSubscribersOverTime,
  AnalyticsNewSubscriptionsOverTime,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { reduce } from "lodash";

import { IDatePickerRange, ISimpleReportingDataContext, dateOptions } from "./constants";
import { IUserSubscriptionCancellationProps } from "../AdminChurnPreventionRoute/CancellationReasonsSettings";

export const formatDateForPrint = (date: Date) => date.toLocaleDateString("en-US", dateOptions);

const getFormattedDates = (startingDate: Date) => [
  new Date(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate()),
  new Date(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate()),
];

/**
 * All functions will return a range of dates including `getToday` & `getYesterday`
 * those will return `start` and `end` dates which will be identical
 */

export const getToday = (): IDatePickerRange => {
  const [start, end] = getFormattedDates(new Date());
  return { start, end }; // Returning a range where start & end are the same
};

export const getYesterday = (daysLookUp = 1, startDate?: Date): IDatePickerRange => {
  const data = startDate ? startDate : new Date();
  const [start, end] = getFormattedDates(data);
  start.setDate(start.getDate() - daysLookUp);
  end.setTime(start.getTime());
  return { start, end }; // Returning a range where start & end are the same
};

export const getRangeByDays = (daysLookUp = 6, startDate?: Date): IDatePickerRange => {
  const data = startDate ?? new Date();
  const [start, end] = getFormattedDates(data);
  end.setDate(start.getDate() - 1); // Setting date to yesterday
  start.setTime(end.getTime()); // this ensures that if daysLookUp stretches into previous month then months will not be confused
  start.setDate(end.getDate() - daysLookUp); // Setting date to x number of days from yesterday
  return { start, end };
};

export const getRangeByMonths = (monthsLookUp = 1, startDate?: Date): IDatePickerRange => {
  const data = startDate ?? new Date();
  const start = new Date(data.getFullYear(), data.getMonth(), data.getDate());
  start.setMonth(start.getMonth() - monthsLookUp);
  start.setDate(1);
  const end = new Date(start.getFullYear(), start.getMonth() + 1, 0); // calc last day of selected month
  return { start, end };
};

export const getRangeByYears = (yearsLookUp = 1, startDate?: Date): IDatePickerRange => {
  const data = startDate ?? new Date();
  const start = new Date(data.getFullYear(), 0, 1);
  start.setFullYear(start.getFullYear() - yearsLookUp);
  start.setDate(1);
  const end = new Date(start.getFullYear() + 1, 0, 0); // calc last day of selected month
  return { start, end };
};

export const getTomorrowTimestampRange = () => {
  const date = new Date();
  const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  tomorrow.setDate(tomorrow.getDate() + 1);
  const todayTimestamp = Date.parse(date.toDateString());
  const tomorrowTimestamp = Date.parse(`${tomorrow.toDateString()} 23:59:59`);
  return {
    todayTimestamp,
    tomorrowTimestamp,
  };
};

export const getOneWeekTimestampRange = () => {
  const [start, end] = getFormattedDates(new Date());
  start.setDate(start.getDate() - 1);
  end.setDate(end.getDate() - 7);

  const startTimestamp = Date.parse(start.toDateString());
  const endTimeStamp = Date.parse(`${end.toDateString()} 23:59:59`);
  return {
    startTimestamp,
    endTimeStamp,
  };
};

export const filterPurchaseStates = (
  purchaseStates: IPurchaseStateWithCustomerRelationship[],
  quickSearchText: string
) => {
  return purchaseStates.filter(item => {
    const { custRel, shopifyId } = item;
    const searchString = quickSearchText.toLowerCase();
    const name = `${custRel?.firstName} ${custRel?.lastName}`.toLowerCase();
    const numberShopifyId = viewShopifyId(shopifyId);
    const findEmail = custRel?.email?.toLowerCase().indexOf(searchString);
    const findName = name.indexOf(searchString);
    const findNumber = numberShopifyId.indexOf(searchString);
    if (findEmail !== -1 || findName !== -1 || findNumber !== -1) {
      return item;
    }
  });
};

type dataType =
  | AnalyticsActiveSubsOverTime
  | AnalyticsNewOrdersOverTime
  | AnalyticsNewSubscribersOverTime
  | AnalyticsNewSubscriptionsOverTime
  | AnalyticsCancelledSubsOverTime;

//need to fix types
export const reduceAnalytics = <T extends keyof ISimpleReportingDataContext>(dataArr: any, type: T) =>
  reduce(dataArr, (sum, item) => sum + Number(item[type]), 0);

export const analyticsData = <T extends keyof ISimpleReportingDataContext>(
  currentData: ISimpleReportingDataContext[T],
  // There will be times where beforeDate's key will not match currentData's - so setting this to keyof ISimpleReportingDataContext instead of T
  beforeData: ISimpleReportingDataContext[keyof ISimpleReportingDataContext],
  context: T
) => {
  const currentCount = reduceAnalytics(currentData, context);
  const beforeCount = reduceAnalytics(beforeData, context);
  const percent =
    beforeCount === 0 && currentCount === 0
      ? 0
      : beforeCount === 0
      ? 100
      : ((currentCount - beforeCount) / beforeCount) * 100;
  return {
    count: context === "totalOrdersRevenueInCents" ? currentCount / 100 : currentCount,
    percent: Math.abs(Math.round(percent)),
    increase: percent > 0,
  };
};

export const analyticsDataMax = (currentData: dataType, beforeData: dataType, field: string) => {
  const currentMax = reduceAnalyticsMax(currentData, field);
  const beforeMax = reduceAnalyticsMax(beforeData, field);
  const percent =
    beforeMax === 0 && currentMax === 0
      ? 0
      : beforeMax === 0
      ? 100
      : ((currentMax - beforeMax) / beforeMax) * 100;
  return {
    count: currentMax,
    percent: Math.abs(Math.round(percent)),
    increase: percent > 0,
  };
};

export const reduceAnalyticsMax = (analyticsArray: any, field: string) => {
  return reduce(
    analyticsArray,
    (max, item) => {
      return Math.max(max, Number(item[field]));
    },
    0
  );
};

export enum HomePageTableTab {
  ACTIVE,
  FAILED,
  CANCELLED,
}

export const getFilterStatus = (selectedTab: HomePageTableTab) => {
  switch (selectedTab) {
    case HomePageTableTab.ACTIVE: {
      return SubscriptionContractSubscriptionStatus.Active;
    }
    case HomePageTableTab.FAILED: {
      return SubscriptionContractSubscriptionStatus.Paused;
    }
    case HomePageTableTab.CANCELLED: {
      return SubscriptionContractSubscriptionStatus.Cancelled;
    }
  }
};

export const areRangesEqual = (a: IDatePickerRange, b: IDatePickerRange) =>
  a.start.getTime() === b.start.getTime() && a.end.getTime() === b.end.getTime();

export const linkCancellationReasonToPurchaseState = (
  purchaseStates: IPurchaseStateWithCustomerRelationship[],
  reasons: IUserSubscriptionCancellationProps[]
) => {
  return purchaseStates.map(ps => {
    const reason = reasons.filter(reason => reason.st.id === ps.id);
    const reasonText =
      reason && reason.length ? reason?.[0].cancellationReason.text?.["en-US"] : "No Reason Selected";
    return {
      ...ps,
      cancellationReason: reasonText,
    };
  });
};
