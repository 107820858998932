import {
  AnalyticsActiveSubsOverTime,
  AnalyticsCancelledSubsOverTime,
  AnalyticsNewOrdersOverTime,
  AnalyticsNewSubscribersOverTime,
  AnalyticsNewSubscriptionsOverTime,
  IAnalyticsSimpleRes,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { capitalize } from "lodash";
import styled from "styled-components";

export const HomePageDateRangeWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 17px;
`;

export const links = [
  {
    text: "What is Smartrr's white-glove onboarding process?",
    link: "https://help.smartrr.com/docs/getting-started/onboarding/what-is-smartrrs-white-glove-onboarding-process",
  },
  {
    text: "How do I set up Subscription Programs?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-subscription-programs",
  },
  {
    text: "How can I customize the theme of my store's Account Portal",
    link: "https://help.smartrr.com/docs/support/admin-portal/how-can-i-customize-the-theme-of-my-stores-account-portal",
  },
  {
    text: "How do I set up subscription discount codes?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-subscription-discount-codes",
  },
  {
    text: "How do customers access their Account Portal?",
    link: "https://help.smartrr.com/docs/support/account-portal/how-do-customers-access-their-account-portal",
  },
  {
    text: "How do I view and adjust my customers' subscriptions?",
    link: "https://help.smartrr.com/docs/support/admin-portal/how-do-i-view-and-adjust-my-customers-subscriptions",
  },
  {
    text: "How do I offer prepaid subscriptions?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-offer-prepaid-subscriptions",
  },
  {
    text: "How do I set up shipping methods for subscription products?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-shipping-methods-for-subscription-products",
  },
  {
    text: "What are subscription anchor dates?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/what-are-subscription-anchor-dates",
  },
  {
    text: "How do I set up email subscription notifications?",
    link: "https://help.smartrr.com/docs/support/subscription-setup/how-do-i-set-up-email-subscription-notifications",
  },
];

export const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
} as Intl.DateTimeFormatOptions;

export enum DatePickerOption {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  LAST_7_DAYS = "Last 7 days",
  LAST_30_DAYS = "Last 30 days",
  LAST_90_DAYS = "Last 90 days",
  LAST_MONTH = "Last month",
  LAST_YEAR = "Last year",
  CUSTOM = "Custom",
}

export const options: {
  label: DatePickerOption;
  value: DatePickerOption;
}[] = Object.entries(DatePickerOption)
  .filter(key => key[1] !== DatePickerOption.CUSTOM)
  .map(key => ({
    label: capitalize(key[0]).replaceAll("_", " ") as DatePickerOption,
    value: key[1] as DatePickerOption,
  }));

export interface ISimpleReportingDataContext {
  totalOrders: AnalyticsNewOrdersOverTime;

  totalOrdersRevenueInCents: AnalyticsNewOrdersOverTime;

  activeSubscriptions: AnalyticsActiveSubsOverTime;
  beforeActiveSubs: AnalyticsActiveSubsOverTime;
  activeSubscribers: AnalyticsActiveSubsOverTime;

  newSubscribers: AnalyticsNewSubscribersOverTime;

  cancelledSubscriptions: AnalyticsCancelledSubsOverTime;

  AnalyticsNewSubscriptionsOverTime: AnalyticsNewSubscriptionsOverTime;
}

export enum SimpleAnalyticCards {
  ACTIVE_SUBSCRIPTIONS = "Active subscriptions",
  NEW_ORDERS = "Orders",
  REVENUE = "Revenue",
  ACTIVE_SUBSCRIBERS = "Active subscribers",
  NEW_SUBSCRIBERS = "New subscribers",
  CANCELED_SUBSCRIPTIONS = "Canceled subscriptions",
}

export interface ISimpleAnalyticCardProps {
  card: ISimpleAnalyticCards;
  analyticsSimple: IAnalyticsSimpleRes;
  beforeAnalytics: IAnalyticsSimpleRes;
}

interface ISimpleAnalyticCards {
  id: number;
  title: SimpleAnalyticCards;
  icon: boolean;
  tooltip: string;
}

export const simpleAnalyticsCards: ISimpleAnalyticCards[] = [
  {
    id: 1,
    title: SimpleAnalyticCards.ACTIVE_SUBSCRIPTIONS,
    icon: true,
    tooltip: "Active subscriptions over selected date range. Paused and expired subscriptions are excluded.",
  },
  {
    id: 2,
    title: SimpleAnalyticCards.NEW_ORDERS,
    icon: false,
    tooltip: "A count of paid orders over selected date range. Processing and failed transactions are excluded.",
  },
  {
    id: 3,
    title: SimpleAnalyticCards.REVENUE,
    icon: false,
    tooltip:
      "Total revenue, after discounts, of both one-time and subscription order line items from orders placed during the selected date range.",
  },
  {
    id: 4,
    title: SimpleAnalyticCards.ACTIVE_SUBSCRIBERS,
    icon: false,
    tooltip:
      "Customers with at least one active subscription are counted as active subscribers. Paused and expired subscriptions are excluded.",
  },
  {
    id: 5,
    title: SimpleAnalyticCards.NEW_SUBSCRIBERS,
    icon: false,
    tooltip:
      "New subscribers over selected date range. A subscriber is counted as 'new' on the creation date of that customer's first subscription.",
  },
  {
    id: 6,
    title: SimpleAnalyticCards.CANCELED_SUBSCRIPTIONS,
    icon: true,
    tooltip: "Includes canceled subscriptions only. Paused and expired subscriptions are excluded.",
  },
];

export interface IDatePickerRange {
  start: Date;
  end: Date;
}
